import React, { FC, useState } from 'react';
import AnimationExpand from '../../animations/animation-expand/animation-expand';
import AnimationToggleRotate from '../../animations/animation-toggle-rotate/animation-toggle-rotate';
import FieldText from '../../atoms/field-text/field-text';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import ProductListingRefineAccordionItemList from '../product-listing-refine-accordion-item-list/product-listing-refine-accordion-item-list';

interface Props {
  isOpen: boolean;
  onToggleOpen: () => void;
  clearAllLabel: string;
  type: 'radio' | 'checkbox';
  name: string;
  items: { value: string; label: string; count: number }[];
  selectedItems: string[] | null;
  onChange: (selectedItems: string[]) => void;
  wrapInFilter?: boolean;
}

const ProductListingRefineAccordionItem: FC<Props> = ({
  isOpen,
  onToggleOpen,
  clearAllLabel,
  type,
  name,
  items,
  selectedItems,
  onChange,
  wrapInFilter,
}) => {
  const [filterQuery, setFilterQuery] = useState<string>('');

  const filteredItems = filterQuery?.length
    ? items.filter((item) => item.label.startsWith(filterQuery))
    : items;

  const availableFacetsValuesCount = items.reduce<number>(
    (count, facetValue) => count + facetValue.count,
    0
  );

  return (
    <div className="grid gap-2">
      <button
        type="button"
        className="flex flex-row items-center justify-between border-b border-grey-800 py-3 mb-3 focus:outline-none"
        onClick={() => onToggleOpen()}
      >
        <span
          className={`${
            availableFacetsValuesCount === 0 && 'text-grey-600'
          } font-bold`}
        >
          {name}
        </span>
        <AnimationToggleRotate isRotated={!isOpen}>
          <IcomoonIcon name="chevron-up" size="lg" />
        </AnimationToggleRotate>
      </button>
      <AnimationExpand isExpanded={isOpen} className="grid gap-3">
        {wrapInFilter && (
          <>
            <FieldText
              placeholder={`Search ${name}`}
              type="search"
              value={filterQuery}
              onChange={(query) => setFilterQuery(query as string)}
            />
            <div className="custom-scrollbar-primary-thin overflow-y-auto max-h-64 w-full">
              <ProductListingRefineAccordionItemList
                type={type}
                items={filteredItems}
                selectedItems={selectedItems}
                onChange={(newSelectedItems) => onChange(newSelectedItems)}
              />
            </div>
          </>
        )}
        {!wrapInFilter && (
          <ProductListingRefineAccordionItemList
            type={type}
            items={filteredItems}
            selectedItems={selectedItems}
            onChange={(newSelectedItems) => onChange(newSelectedItems)}
          />
        )}
        <AnimationExpand isExpanded={selectedItems?.length > 0}>
          <button
            onClick={() => onChange([])}
            type="button"
            className="text-grey-600 underline focus:outline-none"
          >
            {clearAllLabel}
          </button>
        </AnimationExpand>
      </AnimationExpand>
    </div>
  );
};

ProductListingRefineAccordionItem.defaultProps = {
  wrapInFilter: false,
};

export default ProductListingRefineAccordionItem;
