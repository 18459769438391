import { motion } from 'framer-motion';
import { useRouter } from 'next/dist/client/router';
import React, { FC, useRef, useState } from 'react';
import scrollToElement from '../../../helpers/scroll-to';
import useDidMountEffect from '../../../hooks/use-after-mount-effect/use-after-mount-effect';
import { ProductListingWithFiltersType } from '../../../models/product-listing-with-filters-type';
import { ProductsResultType } from '../../../models/products-result-type';
import { SearchRequestFacetFiltersType } from '../../../models/search-request-facet-filters-type';
import SearchRequestSortTypeEnum from '../../../models/search-request-sort-type-enum';
import ItemPagination from '../../molecules/item-pagination/item-pagination';
import ProductListingGrid from '../../molecules/product-listing-grid/product-listing-grid';
import ProductListingRefine from '../../molecules/product-listing-refine/product-listing-refine';
import ProductListingSort from '../../molecules/product-listing-sort/product-listing-sort';
import ProductListingMatchCount from '../product-listing-match-count/product-listing-match-count';
import NoDataBlock from '../../molecules/no-data-block/no-data-block';
import { serialize, unserialize } from './query-string';
import { ProductsStockMapByRefType } from '../../../models/products-stock-map-by-ref-type';

interface Props extends ProductListingWithFiltersType {
  brandName?: string;
  onQueryChange?: (query: string) => void;
  priorityFacetNames?: string[];
  productsResult: ProductsResultType;
  productsStockByRef: ProductsStockMapByRefType;
  showMatchCount?: boolean;
  showRefineCategories?: boolean;
  hideStockAmount?: boolean;
}

const ProductListingWithFilters: FC<Props> = ({
  brandName,
  labels,
  onQueryChange,
  priorityFacetNames,
  productsResult,
  productsStockByRef,
  showMatchCount,
  showRefineCategories,
  hideStockAmount,
}) => {
  const router = useRouter();
  const [query, setQuery] = useState<{ [key: string]: unknown }>(
    unserialize(router.query)
  );
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>();

  const changeQuery = async (): Promise<void> => {
    setIsFetching(true);

    await router.push({ query: serialize(query) }, null, {
      scroll: false,
    });

    onQueryChange(query.query as string);

    setIsFetching(false);
  };

  useDidMountEffect(() => {
    changeQuery();
  }, [query]);

  useDidMountEffect(() => {
    if (router.query.query) {
      setQuery({
        ...query,
        query: router.query.query,
      });
    }
  }, [router.query.query]);

  const selectedCategoryName = productsResult?.parentCategories?.find(
    (category) => category.id === router.query.category
  )?.name;

  let name: string;
  let showQuotes = true;

  if (router.query.query?.length) {
    name = router.query.query as string;
  } else if (router.query.pdg) {
    name = `Product Discount Group = ${router.query.pdg}`;
  } else if (brandName) {
    name = brandName;
    showQuotes = false;
  }

  if (!productsResult) {
    return <></>;
  }

  return (
    <div
      ref={containerRef}
      className={`
        ${!showMatchCount ? 'border-t border-grey-100' : ''}
        py-component-spacing-mobile lg:py-component-spacing grid grid-cols-1 lg:grid-cols-4 gap-4 lg:gap-8
      `}
    >
      {showMatchCount && (
        <div className="col-span-4">
          <ProductListingMatchCount
            categoryName={selectedCategoryName}
            count={productsResult.totalResults}
            name={name}
            showQuotes={showQuotes}
            weFoundXInXLabel={labels.weFoundXInX}
            weFoundXInXMatchingLabel={labels.weFoundXInXMatchingLabel}
            weFoundXMatchesLabel={labels.weFoundXMatchesLabel}
          />
        </div>
      )}

      {productsResult.totalResults <= 0 ? (
        <div className="col-span-4">
          <NoDataBlock
            heading={labels?.noResultsTitleLabel}
            message={labels?.noResultsBodyLabel}
          />
        </div>
      ) : (
        <>
          <div className="col-span-4 lg:col-span-1 lg:mt-3">
            <ProductListingRefine
              categories={productsResult.categories}
              facets={productsResult.facets}
              labels={labels}
              onSelectedCategoryChange={(category) =>
                setQuery({
                  ...query,
                  category,
                })
              }
              onSelectedFacetsChange={(facets) =>
                setQuery({
                  ...query,
                  facets,
                })
              }
              parentCategories={productsResult.parentCategories}
              priorityFacetNames={priorityFacetNames}
              productCount={productsResult.totalResults}
              selectedCategory={query.category as string}
              selectedFacets={query.facets as SearchRequestFacetFiltersType}
              showCategories={showRefineCategories}
            />
          </div>

          <div className="col-span-4 lg:col-span-3 flex flex-col space-y-4">
            <ProductListingSort
              labels={labels}
              productItemsCount={
                productsResult.results.length +
                productsResult.featuredResults.length
              }
              productItemsTotalCount={productsResult.totalResults}
              sortType={
                Number(query.sortType) || SearchRequestSortTypeEnum.Relevance
              }
              onSortTypeChange={(sortType) =>
                setQuery({ ...query, sortType: String(sortType) })
              }
              page={Number(query.page)}
              pageSize={Number(query.pageSize)}
              onPageSizeChange={(pageSize) =>
                setQuery({ ...query, pageSize: String(pageSize) })
              }
            />

            <ProductListingGrid
              featuredProductItems={productsResult.featuredResults}
              isFetching={isFetching}
              labels={labels}
              productItems={productsResult.results}
              productsStockByRef={productsStockByRef}
              hideStockAmount={hideStockAmount}
            />

            <div className="py-component-spacing">
              <motion.div
                animate={isFetching ? 'isFetching' : 'isNotFetching'}
                variants={{
                  isFetching: { opacity: 0, transition: { duration: 0 } },
                  isNotFetching: { opacity: 1 },
                }}
              >
                <ItemPagination
                  itemsPerPage={parseInt(query.pageSize as string, 10)}
                  totalItems={productsResult.totalResults}
                  onPageChange={(page: number) => {
                    scrollToElement({
                      ref: containerRef.current,
                      offsetHeight: -1,
                      behavior: 'auto',
                    });

                    setQuery({ ...query, page: String(page + 1) });
                  }}
                />
              </motion.div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

ProductListingWithFilters.defaultProps = {
  brandName: null,
  onQueryChange: () => ({}),
  showRefineCategories: false,
  showMatchCount: false,
  priorityFacetNames: [],
  hideStockAmount: false,
};

export default ProductListingWithFilters;
