import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useEffect, useState, useContext } from 'react';
import { ProductListingWithFiltersLabelsType } from '../../../models/product-listing-with-filters-labels-type';
import LoadingSpinner from '../../atoms/loading-spinner/loading-spinner';
import ProductCard from '../product-card/product-card';
import { ProductLineItemType } from '../../../models/product-line-item-type';
import { BasketClearQuoteLabelsType } from '../../../models/basket-clear-quote-labels-type';
import useIsLoggedIn from '../../providers/user/hooks/use-is-logged-in';
import { SpecialPricingType } from '../../../models/special-pricing-type';
import pricingRequest from '../../../services/customer-api/endpoints/pricing/request';
import { BasketAddProductErrorLabelsType } from '../../../models/basket-add-product-error-labels-type';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import ProductTypeEnum from '../../../models/product-type-enum';
import { ProductsStockMapByRefType } from '../../../models/products-stock-map-by-ref-type';

interface Props {
  featuredProductItems: ProductLineItemType[];
  isFetching: boolean;
  labels: ProductListingWithFiltersLabelsType;
  productItems: ProductLineItemType[];
  productsStockByRef: ProductsStockMapByRefType;
  hideStockAmount?: boolean;
}

const ProductListingGrid: FC<Props> = ({
  featuredProductItems,
  isFetching,
  labels: {
    addedToBasketLabel,
    addedToFavouritesLabel,
    addProductErrorHeading,
    addProductErrorDescription,
    addProductErrorBody,
    insufficientClearanceStockError,
    addToBasketLabel,
    addToFavouritesLabel,
    buyPriceLabel,
    clearQuoteAccept,
    clearQuoteCancel,
    clearQuoteDescription,
    clearQuoteHeading,
    clearQuoteHelp,
    featuredLabel,
    listPriceLabel,
    manufacturersReferenceLabel,
    onSaleLabel,
    productCodeLabel,
    savePercentageLabel,
    specialPriceLabel,
    technicalSheetLabel,
    uniqueSellingPointItems,
  },
  productItems,
  productsStockByRef,
  hideStockAmount,
}) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  const pushProductToDataLayer = (
    event: GtmEventNameEnum,
    line: ProductLineItemType
  ) =>
    pushToDataLayer(event, {
      featuredProduct: line.product.properties.featuredProduct === '1',
      productType: ProductTypeEnum.Website,
    });

  const productCardLabels = {
    featuredLabel,
    productCodeLabel,
    manufacturersReferenceLabel,
    technicalSheetLabel,
    buyPriceLabel,
    listPriceLabel,
    onSaleLabel,
    savePercentageLabel,
    specialPriceLabel,
    addToBasketLabel,
    addedToBasketLabel,
    addToFavouritesLabel,
    addedToFavouritesLabel,
    uniqueSellingPointItems,
  };

  const addProductErrorLabels: BasketAddProductErrorLabelsType = {
    addProductErrorHeading,
    addProductErrorDescription,
    addProductErrorBody,
    insufficientClearanceStockError,
  };

  const clearQuoteLabels: BasketClearQuoteLabelsType = {
    clearQuoteAccept,
    clearQuoteCancel,
    clearQuoteDescription,
    clearQuoteHeading,
    clearQuoteHelp,
  };

  const [specialPrices, setSpecialPrices] = useState<SpecialPricingType[]>();
  const isLoggedIn = useIsLoggedIn();

  const featuredProductIds = featuredProductItems.map(
    (item) => item.product.guid
  );

  const filteredProductItems = productItems.filter(
    (productItem) => !featuredProductIds.includes(productItem.product.guid)
  );

  useEffect(() => {
    if (isFetching) {
      setSpecialPrices(null);
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    pricingRequest([...featuredProductItems, ...productItems]).then(
      (response: SpecialPricingType[]) => setSpecialPrices(response)
    );
  }, [isLoggedIn, featuredProductItems, productItems]);

  return (
    <>
      <AnimatePresence>
        {!isFetching && (
          <motion.div
            animate={{ opacity: 1 }}
            className="grid gap-3"
            exit={{ opacity: 0 }}
            initial={{ opacity: 1 }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-3 gap-y-6">
              {featuredProductItems
                .filter(
                  (
                    productItem: ProductLineItemType,
                    index: number,
                    self: ProductLineItemType[]
                  ) => {
                    const productIds = self.map((item) => item.product.guid);

                    return (
                      productIds.indexOf(productItem.product.guid) === index
                    );
                  }
                )
                .map((productItem: ProductLineItemType, index: number) => (
                  <ProductCard
                    key={productItem.product.guid}
                    addProductErrorLabels={addProductErrorLabels}
                    basketItem={productItem}
                    clearQuoteLabels={clearQuoteLabels}
                    featured
                    labels={productCardLabels}
                    onAddToFavouriteClick={() =>
                      pushProductToDataLayer(
                        GtmEventNameEnum.ProductFavourite,
                        productItem
                      )
                    }
                    onTechDataClick={() =>
                      pushProductToDataLayer(
                        GtmEventNameEnum.ProductTechsheet,
                        productItem
                      )
                    }
                    specialPrice={
                      isLoggedIn && specialPrices && !isFetching
                        ? specialPrices[index]
                        : null
                    }
                    stockQuantity={
                      productsStockByRef[
                        productItem.product.properties.internalRef
                      ]?.freeStock
                    }
                    hideStockAmount={hideStockAmount}
                  />
                ))}

              {filteredProductItems.map(
                (productItem: ProductLineItemType, index: number) => (
                  <ProductCard
                    key={productItem.product.guid}
                    addProductErrorLabels={addProductErrorLabels}
                    basketItem={productItem}
                    clearQuoteLabels={clearQuoteLabels}
                    featured={
                      productItem.product.properties.featuredProduct === '1'
                    }
                    labels={productCardLabels}
                    onAddToFavouriteClick={() =>
                      pushProductToDataLayer(
                        GtmEventNameEnum.ProductFavourite,
                        productItem
                      )
                    }
                    onTechDataClick={() =>
                      pushProductToDataLayer(
                        GtmEventNameEnum.ProductTechsheet,
                        productItem
                      )
                    }
                    specialPrice={
                      isLoggedIn && specialPrices && !isFetching
                        ? specialPrices[index + featuredProductItems.length]
                        : null
                    }
                    stockQuantity={
                      productsStockByRef[
                        productItem.product.properties.internalRef
                      ]?.freeStock
                    }
                    hideStockAmount={hideStockAmount}
                  />
                )
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isFetching && (
          <motion.div
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 1 }}
          >
            <LoadingSpinner className="py-96" />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

ProductListingGrid.defaultProps = {
  hideStockAmount: false,
};

export default ProductListingGrid;
