import React, { FC } from 'react';
import { ProductListingMatchCountType } from '../../../models/product-listing-match-count-type';

interface Props extends ProductListingMatchCountType {
  readonly showQuotes?: boolean;
}

const ProductListingMatchCount: FC<Props> = ({
  categoryName,
  count,
  name,
  showQuotes,
  weFoundXInXLabel,
  weFoundXInXMatchingLabel,
  weFoundXMatchesLabel,
}: Props) => {
  let label = weFoundXMatchesLabel;

  if (categoryName) {
    label = weFoundXInXLabel;
  }

  if (name && categoryName) {
    label = weFoundXInXMatchingLabel;
  }

  const labelParts = label?.split(/\{[0-9]+\}/);

  if (!labelParts) {
    return null;
  }

  return (
    <div className="pb-component-spacing-mobile text-center lg:text-left lg:pb-component-spacing border-b border-grey-100">
      <p>
        <span className="font-bold">{labelParts[0]}</span>

        <span className="text-french-blue font-bold">
          {count} product{count > 1 ? 's' : ''}
        </span>

        {categoryName && (
          <>
            <span className="font-bold">{labelParts[1]}</span>

            <span className="text-french-blue font-bold">{categoryName}</span>
          </>
        )}

        {name && <span className="font-bold">{labelParts[2]}</span>}
      </p>

      {name && (
        <h3 className="page-heading-three">
          {showQuotes && <>&lsquo;</>}
          {name}
          {showQuotes && <>&rsquo;</>}
        </h3>
      )}
    </div>
  );
};

ProductListingMatchCount.defaultProps = {
  showQuotes: true,
};

export default ProductListingMatchCount;
