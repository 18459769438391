import React, { FC } from 'react';
import interpolateContent from '../../../helpers/interpolate-content';
import { ProductListingWithFiltersLabelsType } from '../../../models/product-listing-with-filters-labels-type';
import SearchRequestSortTypeEnum from '../../../models/search-request-sort-type-enum';
import FieldSelect from '../../atoms/field-select/field-select';
import useIsLoggedIn from '../../providers/user/hooks/use-is-logged-in';

interface Props {
  labels: ProductListingWithFiltersLabelsType;
  onPageSizeChange: (pagSize: number) => void;
  onSortTypeChange: (sortType: number) => void;
  page: number;
  pageSize: number;
  productItemsCount: number;
  productItemsTotalCount: number;
  sortType: number;
}

const ProductListingSort: FC<Props> = ({
  labels: {
    listingSectionProductsPerPageFieldOptions,
    listingSectionShowLabel,
    listingSectionShowingProductsLabel,
    listingSectionSortByFieldOptions,
    listingSectionSortLabel,
  },
  onPageSizeChange,
  onSortTypeChange,
  page,
  pageSize,
  productItemsCount,
  productItemsTotalCount,
  sortType,
}) => {
  const isLoggedIn = useIsLoggedIn();

  const from = productItemsCount > 0 ? (page - 1) * pageSize + 1 : null;
  const to = productItemsCount > 0 ? from + productItemsCount - 1 : null;

  return (
    <div className="flex items-center justify-between">
      <span className="hidden lg:inline">
        {interpolateContent(
          listingSectionShowingProductsLabel,
          `${from} - ${to}`,
          productItemsTotalCount
        )}
      </span>

      <div className="w-full lg:w-auto flex flex-col lg:flex-row-reverse space-y-4 lg:space-y-0 lg:space-x-4">
        <div className="flex items-center space-x-8 lg:space-x-2 order-1 lg:order-2 lg:w-64">
          <span className="w-16 lg:w-auto">{listingSectionShowLabel}:</span>

          <FieldSelect
            options={listingSectionProductsPerPageFieldOptions
              .split('|')
              .map((option) => ({
                value: Number(option),
                label: `${option} per page`,
              }))}
            value={pageSize}
            onChange={(value) => onPageSizeChange(Number(value))}
            className="w-full"
          />
        </div>

        <div className="flex items-center space-x-8 lg:space-x-2 order-2 lg:order-1 w-full lg:w-64">
          <span className="w-16 lg:w-auto">{listingSectionSortLabel}:</span>

          <FieldSelect
            options={[
              {
                value: SearchRequestSortTypeEnum.Relevance,
                label: listingSectionSortByFieldOptions.split('|')[0],
              },
              {
                value: SearchRequestSortTypeEnum.NameAscending,
                label: listingSectionSortByFieldOptions.split('|')[1],
              },
              {
                value: SearchRequestSortTypeEnum.NameDescending,
                label: listingSectionSortByFieldOptions.split('|')[2],
              },
              {
                value: SearchRequestSortTypeEnum.PriceAscending,
                label: isLoggedIn
                  ? listingSectionSortByFieldOptions.split('|')[5]
                  : listingSectionSortByFieldOptions.split('|')[3],
              },
              {
                value: SearchRequestSortTypeEnum.PriceDescending,
                label: isLoggedIn
                  ? listingSectionSortByFieldOptions.split('|')[6]
                  : listingSectionSortByFieldOptions.split('|')[4],
              },
            ]}
            value={sortType}
            onChange={(value) => onSortTypeChange(Number(value))}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default ProductListingSort;
