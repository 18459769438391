import React, { FC } from 'react';
import useAccordion from '../../../hooks/use-accordion/use-accordion';
import { ProductsResultCategoryType } from '../../../models/products-result-category-type';
import { ProductsResultFacetType } from '../../../models/products-result-facet-type';
import { SearchRequestFacetFiltersType } from '../../../models/search-request-facet-filters-type';
import ProductListingRefineAccordionCategories from '../product-listing-refine-accordion-categories/product-listing-refine-accordion-categories';
import ProductListingRefineAccordionItem from '../product-listing-refine-accordion-item/product-listing-refine-accordion-item';

export interface Props {
  categories?: ProductsResultCategoryType[];
  categoryLabel: string;
  clearAllLabel: string;
  facets?: ProductsResultFacetType[];
  priorityFacetNames?: string[];
  onSelectedCategoryChange?: (category: string) => void;
  onSelectedFacetsChange?: (
    selectedFacets: SearchRequestFacetFiltersType
  ) => void;
  parentCategories?: ProductsResultCategoryType[];
  selectedCategory: string;
  selectedFacets?: SearchRequestFacetFiltersType;
  showCategories?: boolean;
  subCategoryLabel: string;
}

const ProductListingRefineAccordion: FC<Props> = ({
  categories,
  categoryLabel,
  clearAllLabel,
  facets,
  onSelectedCategoryChange,
  onSelectedFacetsChange,
  parentCategories,
  priorityFacetNames,
  selectedCategory,
  selectedFacets,
  showCategories,
  subCategoryLabel,
}: Props) => {
  const { selectedItems, toggleSelectedItem } = useAccordion<string>(
    true,
    facets.map((facet) => facet.name)
  );

  return (
    <div>
      {showCategories && (
        <ProductListingRefineAccordionCategories
          clearAllLabel={clearAllLabel}
          categoryLabel={categoryLabel}
          subCategoryLabel={subCategoryLabel}
          parentCategories={parentCategories}
          categories={categories}
          selectedCategory={selectedCategory}
          onSelectedCategoryChange={(newSelectedCategory) =>
            onSelectedCategoryChange(newSelectedCategory)
          }
        />
      )}
      {facets.map((facet) => (
        <ProductListingRefineAccordionItem
          key={facet.name}
          isOpen={selectedItems.includes(facet.name)}
          onToggleOpen={() => toggleSelectedItem(facet.name)}
          clearAllLabel={clearAllLabel}
          type="checkbox"
          name={facet.displayName}
          items={facet.facetValues.map((facetValue) => ({
            value: facetValue.value,
            label: facetValue.value,
            count: facetValue.count,
          }))}
          selectedItems={selectedFacets[facet.name]}
          onChange={(value) => {
            if (value.length) {
              onSelectedFacetsChange({
                ...selectedFacets,
                [facet.name]: value,
              });
            } else {
              const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                [facet.name]: _,
                ...selectedFacetsWithoutCurrent
              } = selectedFacets;
              onSelectedFacetsChange(selectedFacetsWithoutCurrent);
            }
          }}
          wrapInFilter={priorityFacetNames.includes(facet.name)}
        />
      ))}
    </div>
  );
};

ProductListingRefineAccordion.defaultProps = {
  priorityFacetNames: [],
  showCategories: false,
};

export default ProductListingRefineAccordion;
