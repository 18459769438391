import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useRef, useState, useContext } from 'react';
import { Portal } from 'react-portal';
import getTailwindConfig from '../../../helpers/get-tailwind-config';
import interpolateContent from '../../../helpers/interpolate-content';
import scrollToElement from '../../../helpers/scroll-to';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import useWindowSize from '../../../hooks/use-window-size/use-window-size';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import { ProductListingWithFiltersLabelsType } from '../../../models/product-listing-with-filters-labels-type';
import { ProductsResultCategoryType } from '../../../models/products-result-category-type';
import { ProductsResultFacetType } from '../../../models/products-result-facet-type';
import { SearchRequestFacetFiltersType } from '../../../models/search-request-facet-filters-type';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import IcomoonIconButton from '../../atoms/icomoon-icon-button/icomoon-icon-button';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import ProductListingRefineAccordion from '../product-listing-refine-accordion/product-listing-refine-accordion';

export interface Props {
  categories: ProductsResultCategoryType[];
  facets: ProductsResultFacetType[];
  priorityFacetNames: string[];
  labels: ProductListingWithFiltersLabelsType;
  onSelectedCategoryChange: (category: string) => void;
  onSelectedFacetsChange: (
    selectedFacets: SearchRequestFacetFiltersType
  ) => void;
  parentCategories?: ProductsResultCategoryType[];
  productCount: number;
  selectedCategory: string;
  selectedFacets: SearchRequestFacetFiltersType;
  showCategories?: boolean;
}

const ProductListingRefine: FC<Props> = ({
  categories,
  facets,
  priorityFacetNames,
  labels: {
    facetCategoryLabel,
    facetSectionClearAllLabel,
    facetSectionHeading,
    facetSectionMobileHeading,
    facetSectionSeeProductsLabel,
    facetSubCategoryLabel,
  },
  onSelectedCategoryChange,
  onSelectedFacetsChange,
  parentCategories,
  productCount,
  selectedCategory,
  selectedFacets,
  showCategories,
}: Props) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const container = useRef<HTMLDivElement>();

  const { width: windowWidth } = useWindowSize();
  const { screens } = getTailwindConfig().theme;
  const [page, setPage] = useState<number>(0);
  const isMobile = windowWidth <= parseInt(screens.sm, 10);
  const [shouldShowRefineModal, setShouldShowRefineModal] = useState(false);
  const selectedFacetCount = Object.keys(selectedFacets).reduce<number>(
    (count, key) => count + selectedFacets[key]?.length || 0,
    0
  );

  return (
    <>
      {shouldShowRefineModal && (
        <Portal>
          <div className="bg-white fixed inset-0 z-60 py-4 flex flex-col h-full">
            <div className="flex justify-end">
              <IcomoonIconButton
                name="close"
                size="lg"
                color="french-blue"
                onClick={() => setShouldShowRefineModal(false)}
              />
            </div>
            <div className="flex justify-between px-4 py-2 focus:outline-none items-center">
              <div className="flex space-x-4 justify-start">
                <IcomoonIcon name="filter" color="black" />

                <div className="flex flex-col text-left">
                  <span className="font-extrabold">
                    {facetSectionMobileHeading}
                  </span>

                  <button
                    type="button"
                    onClick={() => onSelectedFacetsChange({})}
                    className="text-left text-grey-600 underline focus:outline-none"
                  >
                    {facetSectionClearAllLabel}
                  </button>
                </div>
              </div>
            </div>

            <motion.div
              animate={{ opacity: 1, height: 'auto' }}
              className={`${
                !isMobile ? 'custom-scrollbar-primary' : ''
              } lg:hidden overflow-y-scroll flex-grow`}
              exit={{ opacity: 0, height: '0', transition: { duration: 0.15 } }}
              initial={{ opacity: 0, height: '0' }}
            >
              <div className="flex flex-col space-y-4 p-4 pt-0">
                <ProductListingRefineAccordion
                  categories={categories}
                  categoryLabel={facetCategoryLabel}
                  clearAllLabel={facetSectionClearAllLabel}
                  facets={facets}
                  onSelectedCategoryChange={(value) => {
                    onSelectedCategoryChange(value);
                    pushToDataLayer(GtmEventNameEnum.CategoryFilter);
                  }}
                  onSelectedFacetsChange={(value) => {
                    onSelectedFacetsChange(value);
                    pushToDataLayer(GtmEventNameEnum.CategoryFilter);
                  }}
                  parentCategories={parentCategories}
                  priorityFacetNames={priorityFacetNames}
                  selectedCategory={selectedCategory}
                  selectedFacets={selectedFacets}
                  showCategories={showCategories}
                  subCategoryLabel={facetSubCategoryLabel}
                />
              </div>
            </motion.div>
            <div className="flex p-4">
              <ButtonPrimary
                className="flex-grow"
                name={interpolateContent(
                  facetSectionSeeProductsLabel,
                  productCount
                )}
                onClick={() => {
                  setShouldShowRefineModal(false);
                  scrollToElement({ ref: container.current });
                }}
              />
            </div>
          </div>
        </Portal>
      )}

      <div className="grid gap-8" ref={container}>
        <AnimatePresence onExitComplete={() => setPage(1)}>
          {page === 0 && (
            <motion.button
              animate={{ opacity: 1 }}
              className="lg:hidden w-full rounded-md flex justify-between p-4 focus:outline-none items-center bg-french-blue text-white"
              exit={{ opacity: 0, transition: { duration: 0.15 } }}
              initial={{ opacity: 1 }}
              onClick={() => {
                setShouldShowRefineModal(true);
              }}
              type="button"
            >
              <div className="flex space-x-4">
                <IcomoonIcon name="filter" color="white" size="xl" />
                <div className="flex flex-col text-left">
                  <span className="font-extrabold">
                    {facetSectionMobileHeading}
                  </span>
                </div>
              </div>

              {selectedFacetCount > 0 && (
                <span className="bg-white rounded-full px-3 py-1 text-french-blue text-sm">
                  {selectedFacetCount}
                </span>
              )}
            </motion.button>
          )}
        </AnimatePresence>

        <div className="hidden lg:flex items-center justify-between">
          <span className="text-xl font-semibold">{facetSectionHeading}</span>

          {selectedFacetCount > 0 && (
            <button
              onClick={() => onSelectedFacetsChange({})}
              type="button"
              className="text-sm text-grey-600 underline focus:outline-none"
            >
              {facetSectionClearAllLabel}
            </button>
          )}
        </div>

        <div className="hidden lg:block">
          <ProductListingRefineAccordion
            categories={categories}
            categoryLabel={facetCategoryLabel}
            clearAllLabel={facetSectionClearAllLabel}
            facets={facets}
            onSelectedCategoryChange={(value) => {
              onSelectedCategoryChange(value);
              pushToDataLayer(GtmEventNameEnum.CategoryFilter);
            }}
            onSelectedFacetsChange={(value) => {
              onSelectedFacetsChange(value);
              pushToDataLayer(GtmEventNameEnum.CategoryFilter);
            }}
            parentCategories={parentCategories}
            priorityFacetNames={priorityFacetNames}
            selectedCategory={selectedCategory}
            selectedFacets={selectedFacets}
            showCategories={showCategories}
            subCategoryLabel={facetSubCategoryLabel}
          />
        </div>
      </div>
    </>
  );
};

ProductListingRefine.defaultProps = {
  showCategories: false,
};

export default ProductListingRefine;
