import React, { FC } from 'react';
import useAccordion from '../../../hooks/use-accordion/use-accordion';
import { ProductsResultCategoryType } from '../../../models/products-result-category-type';
import ProductListingRefineAccordionItem from '../product-listing-refine-accordion-item/product-listing-refine-accordion-item';

export interface Props {
  clearAllLabel: string;
  categoryLabel: string;
  subCategoryLabel: string;
  parentCategories?: ProductsResultCategoryType[];
  categories: ProductsResultCategoryType[];
  selectedCategory: string;
  onSelectedCategoryChange?: (category: string) => void;
}

const ProductListingRefineAccordionCategories: FC<Props> = ({
  clearAllLabel,
  categoryLabel,
  subCategoryLabel,
  parentCategories,
  categories,
  selectedCategory,
  onSelectedCategoryChange,
}) => {
  const { selectedItems, setSelectedItems, toggleSelectedItem } =
    useAccordion<number>(true, [parentCategories?.length || 0]);

  const changeSelectedCategory = (index: number, chosenCategoryId: string) => {
    if (chosenCategoryId) {
      onSelectedCategoryChange(chosenCategoryId);
      setSelectedItems([(parentCategories?.length || 0) + 1]);
    } else {
      onSelectedCategoryChange(parentCategories[index - 1]?.id || null);
    }
  };

  return (
    <>
      {parentCategories?.map((category, i) => (
        <ProductListingRefineAccordionItem
          key={category.id}
          isOpen={selectedItems.includes(i)}
          onToggleOpen={() => toggleSelectedItem(i)}
          clearAllLabel={clearAllLabel}
          type="radio"
          name={i === 0 ? categoryLabel : subCategoryLabel}
          items={[
            {
              value: category.id,
              label: category.name,
              count: category.productCount,
            },
          ]}
          selectedItems={[category.id]}
          onChange={(value) => changeSelectedCategory(i, value[0])}
        />
      ))}
      {!!categories.length && (
        <ProductListingRefineAccordionItem
          isOpen={selectedItems.includes(parentCategories?.length || 0)}
          onToggleOpen={() => toggleSelectedItem(parentCategories?.length || 0)}
          clearAllLabel={clearAllLabel}
          type="radio"
          name={parentCategories?.length ? subCategoryLabel : categoryLabel}
          items={categories.map((category) => ({
            value: category.id,
            label: category.name,
            count: category.productCount,
          }))}
          selectedItems={selectedCategory ? [selectedCategory] : null}
          onChange={(value) =>
            changeSelectedCategory(parentCategories?.length || 0, value[0])
          }
        />
      )}
    </>
  );
};

export default ProductListingRefineAccordionCategories;
