import { ParsedUrlQuery } from 'querystring';
import SearchRequestSortTypeEnum from '../../../models/search-request-sort-type-enum';

export function serialize(query: { [key: string]: unknown }): ParsedUrlQuery {
  const parameters: ParsedUrlQuery = {
    slug: query.slug as string[],
    page: query.page as string,
    pageSize: query.pageSize as string,
    sortType: query.sortType as string,
    pdg: query.pdg as string,
  };

  if (query.query) {
    parameters.query = query.query as string;
  }

  if (query.category) {
    parameters.category = query.category as string;
  }

  if (query.facets && Object.keys(query.facets).length) {
    parameters.facets = JSON.stringify(query.facets as string);
  }

  if (query.pdg) {
    parameters.pdg = query.pdg as string;
  }

  if (query.noRedirect) {
    parameters.noRedirect = query.noRedirect as string;
  }

  return parameters;
}

export function unserialize(query: ParsedUrlQuery): { [key: string]: unknown } {
  return {
    query: query.query,
    slug: query.slug,
    category: query.category,
    page: query.page || 1,
    pageSize: query.pageSize || 24,
    sortType: query.sortType || SearchRequestSortTypeEnum.Relevance,
    facets: query.facets ? JSON.parse(query.facets as string) : {},
    pdg: query.pdg,
    noRedirect: query.noRedirect,
  };
}
